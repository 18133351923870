<!-- 用户管理 > 用户编辑(组件) > 用户附件(组件) -->
<template>
  <div class="projectSubitem" v-loading="loading">
    <UploadList v-model="uploadData" fileCode="user_fj" :staffId="manageId"></UploadList>
    <div class="fullScreenOperation">
      <el-button type="success" :loading="saveloading" @click="save">
        保存
      </el-button>
      <el-button type="info" @click="re">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    UploadList: () => import("@/components/upload/ContractList.vue"),
  },
  inject: ["re"],
  props: {
    manageId: {},
  },
  data() {
    return {
      loading: false,
      saveloading: false,
      uploadData: [],
    };
  },
  watch: {
    manageId: {
      immediate: true,
      handler: function(a) {
        if (a) {
          this.getData();
        }
      },
    },
  },
  created() {},
  methods: {
    getData() {
      this.$api.user
        .getInterviewRecordList(this.manageId)
        .then((res) => {
          this.uploadData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    save() {
      if (
        this.uploadData.some((r) => {
          return r.loading;
        })
      ) {
        this.$message.warning("文件上传中，请稍后再试！");
        return "";
      }
      this.saveloading = true;
      let obj = {
        interviewRecordAddRecords: this.uploadData,
        userInfoId: this.manageId,
      };
      this.$api.user
        .savaUserMt(obj)
        .then((res) => {
          this.saveloading = false;
          this.$message.success("操作成功！");
          this.re();
        })
        .catch((err) => {
          console.log(err);
          this.saveloading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";
</style>
